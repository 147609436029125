import { darkTheme, lightTheme } from './theme'
import { lazy, Suspense, useState } from 'react'
import { ScrollToTop } from './design-system/common/ScrollTop'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { responsiveFontSizes, ThemeProvider } from '@mui/material'


const Home = lazy(() => import('./pages/Home'))
const PageLayout = lazy(() => import('./layouts/PageLayout'))
const Header = lazy(() => import('./design-system/organismes/Header'))
const Footer = lazy(() => import('./design-system/organismes/Footer'))

const darkThemeResponsive = responsiveFontSizes(darkTheme)
const lightThemeResponsive = responsiveFontSizes(lightTheme)

const Router = () => {

    const [theme, setTheme] = useState<'light' | 'dark'>(
        localStorage.getItem('TH.R - theme') as 'light' | 'dark'
    )

    const handleSelectTheme = (themeName: 'dark' | 'light') => {
        setTheme(themeName)
        localStorage.setItem('TH.R - theme', themeName)
    }

    return (
        <ThemeProvider
            theme={
                theme === 'light'
                    ? lightThemeResponsive
                    : darkThemeResponsive
            }
        >
            <BrowserRouter>
                <Suspense>
                    <Header />
                    <ScrollToTop>
                        <Routes>
                            <Route path="/" element={<PageLayout setTheme={handleSelectTheme} />}>
                                <Route index element={<Home />} />
                                {/* 
                                <Route path="faq" element={<FAQ />} />
                                <Route path="terms" element={<Terms />} />
                                <Route path="privacy" element={<Privacy />} />
                                */}
                            </Route>
                        </Routes>
                    </ScrollToTop>
                    <Footer />
                </Suspense>
            </BrowserRouter>
        </ThemeProvider>
    )
}

export default Router
