import { type ITheme } from './interfaces/theme'
import { createTheme, type PaletteOptions } from '@mui/material/styles'

interface PaletteOptionsExtended extends PaletteOptions {
    text: {
        primary: string
        secondary: string
        disabled: string
        invert: string
    }
}

export const DarkTheme: ITheme = {
  background: {
    color: '#0e0e0e'
  },
  logo: {
    filter: 'invert(100%)'
  },
  typography: {
    color: {
      primary: '#ffffff'
    }
  }
}

export const LightTheme: ITheme = {
  background: {
    color: 'rgba(250, 250, 250, 0.83)'
  },
  logo: {
    filter: 'invert(0%)'
  },
  typography: {
    color: {
      primary: '#000000'
    }
  }
}

declare module '@mui/material/styles' {
  interface Theme {
    logo: {
      filter: string
      invert: string
    }
    button: {
      background: string
      color: string
    }
    header: {
      background: string
    }
    boxShadow: {
      default: string
      hover: string
    }
    background: {
      invert: string
    }
    fade: {
      start: string
      end: string
    }
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    logo: {
      filter: string
      invert: string
    }
    button: {
      background: string
      color: string
    }
    header: {
      background: string
    }
    boxShadow: {
      default: string
      hover: string
    }
    background: {
      invert: string
    }
    fade: {
      start: string
      end: string
    }
  }
}

const lightThemePalette: PaletteOptionsExtended = {
  primary: {
    dark: 'rgba(30, 30, 30, 1)',
    main: '#0e0e0e',
    light: 'rgba(1, 102, 255, 0.12)',
    contrastText: '#e77f52'
  },
  secondary: {
    dark: 'rgba(1, 102, 255, 0.10)',
    main: 'rgba(1, 102, 255, 0.12)',
    contrastText: '#e77f52'
  },
  success: {
    dark: 'rgba(212, 241, 204, 1)',
    main: 'rgba(42, 184, 0, 1)'
  },
  warning: {
    dark: 'rgba(255, 229, 212, 1)',
    main: 'rgba(253, 125, 42, 1)'
  },
  error: {
    dark: 'rgba(255, 215, 215, 1)',
    main: 'rgba(255, 57, 57, 1)',
    light: 'rgba(255, 215, 215, 0.12)'
  },
  grey: {
    900: 'rgba(29, 34, 39, 1)',
    800: 'rgba(29, 34, 39, 0.87)',
    700: 'rgba(29, 34, 39, 0.38)',
    600: 'rgba(29, 34, 39, 0.09)',
    500: 'rgba(29, 34, 39, 0.07)',
    400: 'rgba(29, 34, 39, 0.06)',
    300: 'rgba(29, 34, 39, 0.04)',
    200: 'rgba(29, 34, 39, 0.03)',
    100: 'rgba(29, 34, 39, 0.02)'
  },
  text: {
    primary: 'rgba(18, 18, 18, 0.87)',
    secondary: '#363636',
    disabled: 'rgba(29, 34, 39, 0.38)',
    invert: 'rgb(255, 255, 255)'
  },
  background: {
    default: '#fcfcfc',
    paper: 'rgba(255, 255, 255, 1)'
  },
  tonalOffset: 0.05,
  mode: 'light'
}

const darkThemePalette: PaletteOptionsExtended = {
  primary: {
    dark: '#fcfcfc',
    main: '#fcfcfc',
    light: 'rgba(1, 102, 255, 0.12)',
    contrastText: '#e77f52'
  },
  secondary: {
    dark: 'rgba(1, 102, 255, 0.10)',
    main: 'rgba(1, 102, 255, 0.12)',
    contrastText: 'r#e77f52'
  },
  success: {
    dark: 'rgba(212, 241, 204, 1)',
    main: 'rgba(42, 184, 0, 1)'
  },
  warning: {
    dark: 'rgba(255, 229, 212, 1)',
    main: 'rgba(253, 125, 42, 1)'
  },
  error: {
    dark: 'rgba(255, 215, 215, 1)',
    main: 'rgba(255, 57, 57, 1)',
    light: 'rgba(255, 215, 215, 0.12)'
  },
  grey: {
    900: 'rgba(29, 34, 39, 1)',
    800: 'rgba(29, 34, 39, 0.87)',
    700: 'rgba(29, 34, 39, 0.38)',
    600: 'rgba(29, 34, 39, 0.09)',
    500: 'rgba(29, 34, 39, 0.07)',
    400: 'rgba(29, 34, 39, 0.06)',
    300: 'rgba(29, 34, 39, 0.04)',
    200: 'rgba(29, 34, 39, 0.03)',
    100: 'rgba(29, 34, 39, 0.02)'
  },
  text: {
    primary: 'rgb(255, 255, 255)',
    secondary: '#e5e5e5',
    disabled: 'rgba(29, 34, 39, 0.38)',
    invert: 'rgba(18, 18, 18, 0.92)'
  },
  background: {
    default: '#0e0e0e',
    paper: '#0b0b0b'
  },
  tonalOffset: 0.05,
  mode: 'dark'
}

export const lightTheme = createTheme({
  palette: lightThemePalette,
  typography: {
    fontFamily: 'Yellix',
    fontWeightRegular: 400,
    fontWeightBold: 700,
    h1: {
        fontSize: '2.5rem',
        lineHeight: '1.2'
      },
      h2: {
        fontSize: '1.7    5rem',
        lineHeight: '1.3'
      },
      h3: {
        fontSize: '1.5rem',
        lineHeight: '1'
      },
      h4: {
        fontSize: '1.3rem',
        lineHeight: '1.3'
      },
      h5: {
        fontSize: '1.2rem',
        lineHeight: '1.3'
      },
      h6: {
        fontSize: '1.1rem',
        lineHeight: '1.6'
      },
      subtitle1: {
        fontSize: '1.05rem',
        lineHeight: '1.6'
      },
      body1: {
        fontSize: '1rem',
        lineHeight: '1.6'
  
      }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 650,
      md: 950,
      lg: 1200,
      xl: 1536
    }
  },
  zIndex: {
    tooltip: 9999
  },
  logo: {
    filter: 'invert(0%)',
    invert: 'invert(100%)'
  },
  button: {
    background: '#000000',
    color: '#ffffff'
  },
  header: {
    background: 'rgba(255, 255, 255, 1)'
  },
  boxShadow: {
    default: '1px 3px 10px #5c5c5c29',
    hover: '2px 4px 12px #5c5c5c29'
  },
  background: {
    invert: '#000'
  },
  fade: {
    start: 'rgba(255, 255, 255, 0)',
    end: 'rgba(255, 255, 255, 0.9)'
  },
  components: {
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true
      }
    },
    MuiButtonBase: {
      defaultProps: {
        // The props to apply
        disableRipple: true // No more ripple, on the whole application 💣!
      }
    }
  }
})

export const darkTheme = createTheme({
  palette: darkThemePalette,
  typography: {
    fontFamily: 'Yellix',
    fontWeightRegular: 400,
    fontWeightBold: 700,
    h1: {
      fontSize: '2.5rem',
      lineHeight: '1.2'
    },
    h2: {
      fontSize: '1.7    5rem',
      lineHeight: '1.3'
    },
    h3: {
      fontSize: '1.5rem',
      lineHeight: '1'
    },
    h4: {
      fontSize: '1.3rem',
      lineHeight: '1.3'
    },
    h5: {
      fontSize: '1.2rem',
      lineHeight: '1.3'
    },
    h6: {
      fontSize: '1.1rem',
      lineHeight: '1.6'
    },
    subtitle1: {
      fontSize: '1.05rem',
      lineHeight: '1.6'
    },
    body1: {
      fontSize: '1rem',
      lineHeight: '1.6'

    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 650,
      md: 950,
      lg: 1200,
      xl: 1536
    }
  },
  zIndex: {
    tooltip: 9999
  },
  logo: {
    filter: 'invert(100%)',
    invert: 'invert(0%)'
  },
  button: {
    background: 'rgba(250, 250, 250, 0.83)',
    color: '#000000'
  },
  header: {
    background: '#r000614'
  },
  boxShadow: {
    default: '0px 0px 30px #000',
    hover: '2px 4px 12px #0000004d'
  },
  background: {
    invert: '#1f1f1f'
  },
  fade: {
    start: 'rgba(14, 14, 14, 0)',
    end: 'rgba(14, 14, 14, 0.9)'
  },
  components: {
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true
      }
    },
    MuiButtonBase: {
      defaultProps: {
        // The props to apply
        disableRipple: true // No more ripple, on the whole application 💣!
      }
    }
  }
})
