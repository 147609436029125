import Scroll from 'react-scroll'
import { useLocation } from 'react-router'
import { useEffect, useState } from 'react'

export const ScrollToTop = ({ ...props }) => {
    const location = useLocation()
    const [oldLocation, setOldLocation] = useState<string>('')

    useEffect(() => {
        if (location.pathname !== oldLocation) {
            window.scrollTo(0, 0)
            setOldLocation(location.pathname)
        }
    }, [location])

    return <>{props.children}</>
}

export const scrollTo = (id: string) => {
    Scroll.scroller.scrollTo(id, {
        duration: 500,
        delay: 0,
        smooth: true,
        offset: -500
    })
}
